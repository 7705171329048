/* eslint-disable no-underscore-dangle */
import type { NextPage, GetStaticProps } from 'next';
import { memo, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import { parseLocale } from '../utilities/parsers';
import { getAmplienceContent } from '../utilities/ssr/cacheAmplienceContent';
import { showLayoutContentForHomepage } from '../utilities/amplience';
import { StaticContextProvider } from '../utilities/context/static/StaticContext';
import { SearchContextProvider } from '../utilities/context/dynamic/SearchContext';
import { HomepageMetaHead } from '../components/shared/metaHead/HomepageMetaHead';
import { MasterLayout } from '../components/shared/core/layouts/MasterLayout';
import { ClearPreviewModeCookiesButton } from '../components/shared/cookie/ClearPreviewModeCookiesButton';
import type { MasterLayout as MasterLayoutSchema } from '../amplienceTypes/schemas/imported/master-layout-schema';
import { CookieProvider } from '../utilities/context/dynamic/CookieContext';
import {
  HOMEPAGE_LAYOUT_SLOT_DELIVERY_KEY,
  MASTER_LAYOUT_SLOT_DELIVERY_KEY,
  QUICK_VIEW_OVERLAY_DELIVERY_KEY,
} from '../utilities/constants/amplienceDeliveryKeys';
import type { PreviewData } from './api/preview';
import { Placeholder } from '../components/shared/placeholder/Placeholder';
import { filterCommonAmplienceContents, getCommonStaticProps } from '../utilities/ssr/staticProps';
import { DataLayerProvider } from '../utilities/context/static/DataLayerContext';
import { NavActionContextProvider } from '../utilities/context/dynamic/NavActionContext';
import type { GlobalAccessibilityLabels } from '../amplienceTypes/schemas/imported/global-accessibility-labels-schema';
import { a11yInvisibleStyle } from '../components/shared/common/styles';
import type { CommonPageProps } from '../types/props';
import { PageTypes } from '../utilities/constants';
import { handleCustomQubitEvent } from '../utilities/vendors';
import logger from '../utilities/logger';

const A11ySkipToMain = styled.a`
  ${a11yInvisibleStyle}
`;

interface HomepageProps extends CommonPageProps {
  isPreviewMode: boolean;
}

const Homepage: NextPage<HomepageProps> = ({
  genericLayoutSlotContent,
  masterLayoutSlotContent,
  leftNavigation,
  flyoutNavigation,
  outletFlyoutNavigation,
  inpageNavigation,
  configuration,
  socialMediaChannels,
  countryEn,
  quickViewContent,
  isPreviewMode,
}: HomepageProps) => {
  useEffect(() => {
    if ('PerformanceObserver' in window) {
      new PerformanceObserver(entryList => {
        // eslint-disable-next-line no-restricted-syntax
        for (const entry of entryList.getEntries()) {
          const lcpElement = (entry as { element: Element } & PerformanceEntry).element;

          lcpElement?.setAttribute('loading', 'eager');
        }
      }).observe({ type: 'largest-contentful-paint', buffered: true });
    }
  }, []);

  useEffect(() => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const experienceId = urlSearchParams.get('qeId');
      const isTk37 = experienceId?.endsWith('tk37_v1');
      const variationIsControl = !experienceId || !isTk37;
      const gender = urlSearchParams.get('gxt');
      const qubitAssets = document.querySelectorAll('[data-qubit-id]');

      qubitAssets.forEach(asset => {
        const dataQubitIdValue = asset.getAttribute('data-qubit-id');
        const isControlAsset = dataQubitIdValue?.includes('control');

        if (variationIsControl && isControlAsset) {
          asset.classList.remove('is-hidden');

          if (experienceId?.endsWith('c') && isTk37) {
            handleCustomQubitEvent('ctaClickEvent', asset, experienceId, variationIsControl);
          }
        }

        if (!variationIsControl) {
          if (isControlAsset) {
            asset.setAttribute('hidden', 'true');
          } else if (isTk37) {
            if (gender === '0' && dataQubitIdValue === 'TK37/male') {
              asset.removeAttribute('hidden');
              handleCustomQubitEvent('ctaClickEvent', asset, experienceId, variationIsControl);
            } else if (gender === '1' && dataQubitIdValue === 'TK37/female') {
              asset.removeAttribute('hidden');
              handleCustomQubitEvent('ctaClickEvent', asset, experienceId, variationIsControl);
            }
          }
        }
      });
    } catch (error) {
      const controlAssets = document.querySelectorAll('[data-qubit-id="control"]');

      controlAssets.forEach(asset => {
        asset.classList.remove('is-hidden');
      });

      logger.error(`A/B test error: ${(error as Error).message}`);
    }
  }, []);

  if (!masterLayoutSlotContent) {
    return <Placeholder />;
  }

  const main = genericLayoutSlotContent?.slotContent ? (
    showLayoutContentForHomepage(genericLayoutSlotContent?.slotContent)
  ) : (
    <Placeholder />
  );

  const metadataContent = genericLayoutSlotContent?.metaData;
  const uspListContent = genericLayoutSlotContent?.uspList;

  return (
    <>
      <A11ySkipToMain href="#container">
        {(
          masterLayoutSlotContent?.slotContent?.accessibilityLabels
            ?.content as GlobalAccessibilityLabels
        )?.skipToMain ?? ''}
      </A11ySkipToMain>
      <div className="page--landingPage has-mouse">
        <StaticContextProvider
          pageType={PageTypes.HOME}
          configuration={configuration || {}}
          leftNavigation={leftNavigation || {}}
          flyoutNavigation={flyoutNavigation || {}}
          outletFlyoutNavigation={outletFlyoutNavigation || {}}
          inpageNavigation={inpageNavigation || {}}
          socialMediaChannels={socialMediaChannels || []}
          quickViewContent={quickViewContent}
          countryEn={countryEn || {}}
          accessibilityLabels={masterLayoutSlotContent?.slotContent?.accessibilityLabels?.content}
          formErrorLabels={masterLayoutSlotContent?.slotContent?.formErrorLabels?.content}
          notifications={masterLayoutSlotContent?.slotContent?.notifications?.content}
        >
          <NavActionContextProvider
            defaultValue={genericLayoutSlotContent?.pageProperties?.sidenavDefaultOpen}
          >
            <DataLayerProvider>
              <SearchContextProvider>
                <HomepageMetaHead metadataContent={metadataContent} />
                <CookieProvider>
                  {isPreviewMode && <ClearPreviewModeCookiesButton />}
                  <MasterLayout
                    pageProperties={genericLayoutSlotContent?.pageProperties}
                    main={main}
                    uspListContent={uspListContent}
                    pageType={PageTypes.HOME}
                    {...(masterLayoutSlotContent?.slotContent as MasterLayoutSchema)}
                  />
                </CookieProvider>
              </SearchContextProvider>
            </DataLayerProvider>
          </NavActionContextProvider>
        </StaticContextProvider>
      </div>
    </>
  );
};

export default memo(Homepage, isEqual);

export const getStaticProps: GetStaticProps = async ({ locale, preview, previewData }) => {
  const logger = (await import('../utilities/logger')).default;

  if (!locale || locale === 'default') {
    logger.warn('Locale not found');

    return {
      notFound: true,
    };
  }

  try {
    const commonProps = await getCommonStaticProps(locale, '', PageTypes.HOME);

    if (!commonProps.configuration) {
      logger.warn('Configuration data is empty from backend.');

      return {
        notFound: true,
      };
    }

    const { language, country } = parseLocale(locale);

    const homepageLayoutSlotDeliveryKey =
      commonProps.configuration.homepageLayoutSlotDeliveryKey || HOMEPAGE_LAYOUT_SLOT_DELIVERY_KEY;
    const masterLayoutSlotDeliveryKey =
      commonProps.configuration.masterLayoutSlotDeliveryKey || MASTER_LAYOUT_SLOT_DELIVERY_KEY;
    const quickViewDeliveryKey =
      commonProps.configuration.quickViewOverlayDeliveryKey || QUICK_VIEW_OVERLAY_DELIVERY_KEY;

    const amplienceContents = await getAmplienceContent(
      [
        { key: homepageLayoutSlotDeliveryKey },
        { key: masterLayoutSlotDeliveryKey },
        { key: quickViewDeliveryKey },
      ],
      `${language}-${country.toUpperCase()},${language},en`,
      previewData as PreviewData
    );

    return {
      props: {
        ...commonProps,
        ...filterCommonAmplienceContents(amplienceContents, PageTypes.HOME),
        isPreviewMode: !!preview,
      },
      revalidate: 60 * 8,
    };
  } catch (error) {
    logger.error(`Error caught for homepage: ${(error as Error).message}`);

    return {
      notFound: true,
    };
  }
};
