import type { ReactElement } from 'react';
import styled from 'styled-components';
import Image from 'next/legacy/image';
import router from 'next/router';
import fetchService from '../../../utilities/helpers/fetchService';

import logger from '../../../utilities/logger';

const S = {
  Button: styled.button`
    position: fixed;
    top: 35%;
    right: 0;
    z-index: 1000;
  `,
};

export const ClearPreviewModeCookiesButton = (): ReactElement => {
  const clearPreviewModeCookies = async () => {
    const response = await fetchService.get('/api/clear-preview-mode-cookies');

    if (response.status === 200) {
      router.reload();
    } else {
      // Error happened while clearing the preview mode cache
      logger.error('Error happened while clearing the preview mode cache');
    }
  };

  return (
    <S.Button type="button" title="Click to cancel Preview Mode" onClick={clearPreviewModeCookies}>
      <Image
        src={`/_fes/${process.env.BUILD_TIME}/img/amplience.webp`}
        alt="amplience"
        style={{
          width: '40px',
          height: '40px',
        }}
      />
    </S.Button>
  );
};
